import { PrismicRichText } from '@prismicio/react';
import { component, ListedFeaturesMainComponentProps } from '.';
import { PrismicNextImage } from '@prismicio/next';

const ListedFeaturesDesktopGrid = ({
  slice,
}: ListedFeaturesMainComponentProps): JSX.Element => {
  return (
    <ul className="hidden flex-wrap justify-between py-2 ~mt-14/20 sm:flex xl:justify-between xl:gap-x-8 xl:gap-y-11">
      {slice.primary.features.map((feature, index) => (
        <li
          key={index}
          className="flex w-[33.33%] flex-col items-center px-4 ~gap-8/4 ~py-6/4 xl:max-w-72"
        >
          <PrismicNextImage
            field={feature.icon}
            className="aspect-square max-h-[100px] max-w-[100px] object-contain"
            fallbackAlt=""
          />
          <div className="flex flex-col items-center ~gap-4/6">
            <PrismicRichText field={feature.heading} components={component} />
            <PrismicRichText
              field={feature.description}
              components={{
                paragraph: ({ children }) => (
                  <p className="mx-auto max-w-prose text-pretty text-center text-base font-normal sm:~lg:~text-base/lg">
                    {children}
                  </p>
                ),
              }}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ListedFeaturesDesktopGrid;
