import { PrismicRichText } from '@prismicio/react';
import { component, ListedFeaturesMainComponentProps } from '.';
import { PrismicNextImage } from '@prismicio/next';
import SwiperCore from 'swiper';
import { Pagination, Keyboard, Mousewheel, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Pagination, Keyboard, Mousewheel, Autoplay]);
const ListedFeaturesCarousel = ({
  slice,
}: ListedFeaturesMainComponentProps): JSX.Element => {
  // `loop` will only work if the total number of slides is equal or greater than slidesPerView + slidesPerGroup
  const isLoopSlides = slice.primary.features.length > 3;
  return (
    <Swiper
      className="full-bleed !pb-11 ~mt-14/20"
      loop={isLoopSlides}
      pagination={{
        clickable: true,
      }}
      wrapperClass="!box-border !h-auto !items-stretch py-2"
      spaceBetween={8}
      wrapperTag="ul"
      keyboard={{
        enabled: true,
      }}
      slidesPerView={1.5}
      centeredSlides
    >
      {slice.primary.features.map((feature, index) => (
        <SwiperSlide
          key={index}
          className="!flex !h-auto !flex-col !items-center rounded-2xl bg-white px-4 shadow-arc-default ~gap-8/4 ~py-6/4"
          tag="li"
        >
          <PrismicNextImage
            field={feature.icon}
            className="aspect-square max-h-[100px] max-w-[100px] object-contain"
            fallbackAlt=""
          />
          <div className="flex flex-col items-center ~gap-4/6">
            <PrismicRichText field={feature.heading} components={component} />
            <PrismicRichText
              field={feature.description}
              components={{
                paragraph: ({ children }) => (
                  <p className="mx-auto max-w-prose text-pretty text-center text-base font-normal sm:~lg:~text-base/lg">
                    {children}
                  </p>
                ),
              }}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ListedFeaturesCarousel;
