import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  SliceComponentProps,
  PrismicRichText,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import 'swiper/swiper-bundle.css';
import ListedFeaturesDesktopGrid from './ListedFeaturesDesktopGrid';
import ListedFeaturesCarousel from './ListedFeaturesCarousel';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

/**
 * Props for `ListedFeatures`.
 */
export type ListedFeaturesProps =
  SliceComponentProps<Content.ListedFeaturesSlice>;
export type ListedFeaturesMainComponentProps = Omit<
  ListedFeaturesProps,
  'index' | 'slices' | 'context'
>;

/**
 * Component for "ListedFeatures" Slices.
 */

export const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-center text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  heading3: ({ children }) => (
    <h3 className="text-balance text-center font-bold ~lg:~text-arc-xl/arc-2xl">
      {children}
    </h3>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl">
      {children}
    </p>
  ),
};

const ListedFeatures = ({ slice }: ListedFeaturesProps): JSX.Element => {
  const isMobile = useMediaQuery('(max-width: 639px)');
  const isClient = useIsClient();
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="bg-paper text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="px-0 o-6 md:px-16">
          <PrismicRichText
            field={slice.primary.heading}
            components={component}
          />
          <PrismicRichText
            field={slice.primary.sub_heading}
            components={component}
          />
        </div>
        {isClient && isMobile ? (
          <ListedFeaturesCarousel slice={slice} />
        ) : (
          <ListedFeaturesDesktopGrid slice={slice} />
        )}
      </Container>
    </section>
  );
};

export default ListedFeatures;
